import {Injectable} from '@angular/core';
import {ClassGoalUpdateRequest} from 'app/models/class-goal-update-request';
import {ClassMinutesPerMonthGoalResponse} from 'app/models/class-minutes-per-month-goal-response';
import {StudentThoughtsResponse} from 'app/models/student-thoughts-response';
import {Observable} from 'rxjs';
import {isNullOrUndefined} from '../../../src/app/shared/utils/global.utils';
import {ApiClientService} from '../core/api-client.service';
import {CachePriority, HttpAction} from '../core/enums';
import {ApiClientOptions} from '../core/models';
import {PostResponse} from '../core/models/api-post-response';
import {ClassResponse, UserDetail} from '../models';
import {ClassBasicInfoModel} from '../models/class-basic-info.model';
import {ClassUpdateRequest} from '../models/class-update-request';
import {InviteStudentRequest} from '../models/invite-student-request';
import {SendEmailRequest} from '../models/send-email-request';
import {StudentProfileResponse} from '../models/student-profile-response';
import {StudentResponse} from '../models/student-response';
import {StudentNameService} from '../services/student-name.service';
import {HttpContext} from '@angular/common/http';

@Injectable()
export class ClassesService {

    constructor(private apiClient: ApiClientService, private _studentName: StudentNameService) {}

    //Get Section
    GetClassesByStudent(studentId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst, context: HttpContext = null): Observable<ClassResponse[]> {
        let getURL = `api/Students/${studentId}/Classes`;
        const options = new ApiClientOptions(true, cacheLevel);
        options.httpContext = context;
        return this.apiClient.InvokeApi<ClassResponse[]>(getURL, HttpAction.Get, null, options);
    }

    GetClassesByTeacher(teacherId, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<ClassResponse[]> {
        let getURL = `api/Teachers/${teacherId}/Classes`;
        return this.apiClient.InvokeApi<ClassResponse[]>(getURL, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    getClassStudents(classId, schoolId: string = null, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<StudentResponse[]> {
        let getUrl = `api/${schoolId ? 'Schools/' + schoolId + '/' : ''}Classes/${classId}/Students`;
        const options = new ApiClientOptions(true, cacheLevel);
        if (schoolId) options.schoolIdHeader = schoolId;
        return this.apiClient.InvokeApi(getUrl, HttpAction.Get, null, options);
    }

    resetStudentsPassword(classId: string, newPassword: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<PostResponse> {
        let getUrl = `api/Classes/${classId}/ResetStudentsPassword?password=${newPassword}`;
        return this.apiClient.InvokeApi<PostResponse>(getUrl, HttpAction.Post, null, new ApiClientOptions(true, cacheLevel));
    }

    getClass(classId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Promise<ClassResponse> {
        return this.apiClient.InvokeApi<ClassResponse>(`api/Classes/${classId}`, HttpAction.Get,
            null, new ApiClientOptions(true, cacheLevel))
            .toPromise();
    }

    getAvailableClasses(schoolId: string, cacheLevel: CachePriority = CachePriority.CacheFirst): Observable<ClassBasicInfoModel[]> {
        const getURL = `api/Schools/${schoolId}/Classes/AccessibleBasicInfo`;
        return this.apiClient.InvokeApi<ClassBasicInfoModel[]>(getURL, HttpAction.Get, cacheLevel);
    }

    downloadClassLogins(classId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<UserDetail[]> {
        let getUrl = `api/Classes/${classId}/DownloadClassLogins`;
        return this.apiClient.InvokeApi<UserDetail[]>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    getClassStudentsProfile(classId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<StudentProfileResponse[]> {
        let getUrl = `api/Classes/${classId}/StudentProfile`;
        return this.apiClient.InvokeApi<StudentProfileResponse[]>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    getStudentThoughtsCSV(classId: string, studentId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<string> {
        let getUrl = `api/Classes/${classId}/StudentThoughtsCSV?studentId=${studentId}`;
        return this.apiClient.InvokeApi<string>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    getStudentThoughts(classId: string, studentId?: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<StudentThoughtsResponse[]> {
        let getUrl = `api/Classes/${classId}/StudentThoughts`;
        const queryParams = this.apiClient.createURLSearchParams({
          studentId,
        })
        return this.apiClient.InvokeApi<StudentThoughtsResponse[]>(getUrl + queryParams, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    sendLoginsCSV(classId: string, emailRequest: SendEmailRequest, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<PostResponse> {
        let postUrl = `api/Classes/${classId}/EmailClassLogins`;
        return this.apiClient.InvokeApi<PostResponse>(postUrl, HttpAction.Post, emailRequest, new ApiClientOptions(true, cacheLevel));
    }

    sendThoughtsCSV(classId: string, emailRequest: SendEmailRequest, studentId?: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<PostResponse> {
        const postUrl = `api/Classes/${classId}/SendStudentThoughtCsvToEmail`;
        const queryParams = this.apiClient.createURLSearchParams({
          studentId,
        });

        return this.apiClient.InvokeApi<PostResponse>(postUrl + queryParams, HttpAction.Post, emailRequest, new ApiClientOptions(true, cacheLevel));
    }

    GetMetaData(classId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<any> {
        const getUrl = `api/Classes/${classId}/Source`;
        return this.apiClient.InvokeApi<any>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    GetAllClassesByTeacher(teacherId: string, academicYearId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<ClassResponse[]> {
        const getURL = `api/Teachers/${teacherId}/AcademicYears/${academicYearId}/Classes`;
        return this.apiClient.InvokeApi<ClassResponse[]>(getURL, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    updateClass(classId: string,
                payload: ClassUpdateRequest,
                isAdmin: boolean = false,
                schoolId: string = null,
                cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<PostResponse> {
        let url = `api/Classes/${classId}`;
        const options: ApiClientOptions = new ApiClientOptions(true, cacheLevel);
        if (isAdmin && !isNullOrUndefined(schoolId)) {
            options.schoolIdHeader = schoolId;
            url = `api/Schools/${schoolId}/Classes/${classId}`;
        }
        return this.apiClient.InvokeApi<PostResponse>(url, HttpAction.Put, payload, options);
    }

    getMonthlyGoals(schoolId: string, classId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Promise<ClassMinutesPerMonthGoalResponse[]> {
        const url = `api/Schools/${schoolId}/Classes/${classId}/MonthlyGoals`;
        return this.apiClient.InvokeApi<ClassMinutesPerMonthGoalResponse[]>(url, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel)).toPromise();
    }

    updateMonthlyGoals(schoolId: string, classId: string, request: ClassGoalUpdateRequest,
                       cacheLevel: CachePriority = CachePriority.NetworkFirst): Promise<PostResponse[]> {
        const url = `api/Schools/${schoolId}/Classes/${classId}/UpdateGoals`;
        return this.apiClient.InvokeApi<PostResponse[]>(url, HttpAction.Put, request, new ApiClientOptions(true, cacheLevel)).toPromise();
    }

    inviteStudents(classId: string, payload: InviteStudentRequest[]) {
      const url = `api/Classes/${classId}/InviteStudents`;

      return this.apiClient.InvokeApi<void>(url, HttpAction.Post, payload);
    }
}
