import {Injectable} from '@angular/core';
import {StudentResponse} from '../models';

@Injectable({
  providedIn: 'root'
})
export class StudentNameService {
  demoNameMapper = this.mapSurname.bind(this);
  private demoSuffix;

  // Since this module is shared between the two apps, and they each have their own translation mechanism, we have to set it like this
  setDemoSuffix(demoSuffix) {
    this.demoSuffix = demoSuffix;
  }

  mapSurname(stu: StudentResponse): StudentResponse {
    if (/\.student\!_$/.test(stu.username) && this.demoSuffix) {
      if (stu.lastName) {
        stu.lastName += ` - ${this.demoSuffix}`;
      } else {
        stu.lastName = this.demoSuffix;
      }
    }

    return stu;
  }
}
