import { Injectable } from '@angular/core';

@Injectable()
export class BrowserDetectionService {

    static getDeviceType(): string {
        return navigator.userAgent;
    }

    static getBrowserVersion(): string {
        return navigator.appVersion;
    }

    static getApplicationPlatform(): string {
        return navigator.platform;
    }

    static isAndroid(): boolean {
        return /(android)/i.test(navigator.userAgent);
    }

    static isApple(): boolean {
        return /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    static isMobile(): boolean {
        return this.isAndroid() || this.isApple();
    }
}