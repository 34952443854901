import {Injectable} from '@angular/core';
import {UserType} from 'app/enums';
import {SchoolType} from 'app/enums/school-type.enum';
import {GlobalConstants} from 'app/globals';
import {SessionStorageService} from 'app/localstorage/session-storage.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {MediaType} from '../../../projects/consumer/src/app/models/enums/book/media-type.enum';
import {RequiredBy} from '../../../projects/consumer/src/app/utils/utility-types';
import {ApiClientService} from '../core/api-client.service';
import {CachePriority, HttpAction} from '../core/enums';
import {ApiClientOptions, PostResponse} from '../core/models';
import {StudentBasicInfo} from '../core/models/student-basic-info';
import {SchoolAccountStatus} from '../enums/school-account-status.enum';
import {ClassResponse, LibraryIdsResponse, SchoolResponse} from '../models';
import {ClassCreateRequest} from '../models/class-create-request';
import {ClassResponseLite} from '../models/class-response-lite';
import {CountryResponse} from '../models/country-response';
import {GetSchoolResponse} from '../models/get-school-response';
import {PostalAddress} from '../models/postal-address';
import {ProfileLibrary} from '../models/profile-library';
import {SchoolExtendedResponseModel} from '../models/school-extended-response-model';
import {SchoolMetaDataResponse} from '../models/school-meta-data-response';
import {SchoolPurchaseRequest} from '../models/school-purchase-request';
import {SchoolSearchResponse} from '../models/school-search-response';
import {SettingUpdateRequest} from '../models/setting-update.request';
import {isNullOrUndefined} from '../shared/utils/global.utils';
import {HttpContext} from '@angular/common/http';

interface SearchSchoolParams {
  name: string;
  skip: number;
  take: number;
}

interface GetSchoolsByDistrictIdParams {
  districtId: string;
  onlyLaunchedSchools: boolean;
  onlyActiveSchools: boolean;
  schoolType: SchoolType;
  schoolAccountStatus: SchoolAccountStatus;
  withLiteracyBoxes: boolean;
  includeCurrentAcademicYear: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SchoolsService {

  constructor(
    private _apiClient: ApiClientService) {
  }

  searchSchool(
    {
      name,
      skip,
      take = 100,
    }: RequiredBy<SearchSchoolParams, 'name'>,
  ) {
    const url = `wpapi/Search/Schools/${name}`;
    const queryParams = this._apiClient.createURLSearchParams({
      skip,
      take,
    });

    return this._apiClient.InvokeApi<SchoolSearchResponse[]>(url + queryParams, HttpAction.Get);
  }

  //Get Section
  Get(schoolId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst, context: HttpContext = null): Observable<SchoolResponse> {
    const getURL = `api/Schools/${schoolId}`;
    const options = new ApiClientOptions(true, cacheLevel);
    options.schoolIdHeader = schoolId;
    options.httpContext = context;
    return this._apiClient.InvokeApi<SchoolResponse>(getURL, HttpAction.Get, null, options);
  }

  getSchoolById(schoolId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst) {
    const getURL = `wpapi/Schools/${schoolId}`;
    const options = new ApiClientOptions(true, cacheLevel);
    options.schoolIdHeader = schoolId;
    return this._apiClient.InvokeApi<SchoolExtendedResponseModel>(getURL, HttpAction.Get, options);
  }

  GetLibraryIds(schoolId: string, mediaTypes: MediaType[] = [MediaType.Book], lsSharedLibrariesOnly = false, isEncyclopedia: boolean = false, forMyStudentUserId: string = null, communityLibraries: boolean = null, userAuthoredLibraries: boolean = null, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<LibraryIdsResponse> {
    if (lsSharedLibrariesOnly) {
      const libraryIds = [];
      if (mediaTypes.includes(MediaType.Book)) {
        libraryIds.push('1aaeb8d4-8dda-11ea-8866-000af7d55b90');
      }
      if (mediaTypes.includes(MediaType.Video)) {
        libraryIds.push('b4fd8882-bf64-11ea-828c-000af7eac480');
      }
      if (mediaTypes.includes(MediaType.ActionClip)) {
        libraryIds.push('bbf6b208-bf64-11ea-828c-000af7eac480');
      }
      return of({ libraryIds });
    } else if (isEncyclopedia) {
      let getUrl = `api/Schools/${schoolId}/LibraryIds?mediaTypes=${[MediaType.Encyclopedia].join(',')}`;
      if (forMyStudentUserId) {
        getUrl += `&forMyStudentUserId=${forMyStudentUserId}`;
      }
      return this._apiClient.InvokeApi<LibraryIdsResponse>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel, false, true)).pipe(
        map((res: LibraryIdsResponse) => {
          const libraryIds: string[] = [];
          if (mediaTypes.includes(MediaType.Encyclopedia) && res.libraryIds.includes('13307afe-c98c-4964-44f5-08d8854b7c1b')) {
            libraryIds.push('13307afe-c98c-4964-44f5-08d8854b7c1b');
          }

          if (mediaTypes.includes(MediaType.BackInTime) && res.libraryIds.includes('30d96854-3549-11eb-9c2d-000af7eac7d0')) {
            libraryIds.push('30d96854-3549-11eb-9c2d-000af7eac7d0');
          }

          if (mediaTypes.includes(MediaType.SpecialReport) && res.libraryIds.includes('362270c6-3549-11eb-9c2d-000af7eac7d0')) {
            libraryIds.push('362270c6-3549-11eb-9c2d-000af7eac7d0');
          }
          return { libraryIds };
        }),
      );
    } else {
      const libraryIds: string[] = [];
      if (mediaTypes.includes(MediaType.HelpVideo)) {
        libraryIds.push('6c766f38-7a9f-11eb-9662-000af7eac480');
      }

      if (libraryIds.length > 0) {
        return of({ libraryIds });
      }

      let getUrl = `api/Schools/${schoolId}/LibraryIds?mediaTypes=${mediaTypes.join(',')}`;
      if (forMyStudentUserId) {
        getUrl += `&forMyStudentUserId=${forMyStudentUserId}`;
      }
      if (communityLibraries) {
        getUrl += `&communityLibraries=${communityLibraries}`;
      }
      if (userAuthoredLibraries === false && mediaTypes.includes(MediaType.Book)) {
        getUrl += `&userAuthoredLibraries=${userAuthoredLibraries}`;
      }
      return this._apiClient.InvokeApi<LibraryIdsResponse>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel, false, true));
    }
  }

  GetLibraries(schoolId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<ProfileLibrary[]> {
    const getUrl = `api/Schools/${schoolId}/Libraries`;
    return this._apiClient.InvokeApi<ProfileLibrary[]>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
  }

  GetSchoolMetaData(schoolId: string, sendSchoolId: boolean = false, cacheLevel: CachePriority = CachePriority.NetworkFirst, context: HttpContext = null): Observable<SchoolMetaDataResponse> {
    const getURL = `api/Schools/${schoolId}/MetaData`;
    const options: ApiClientOptions = new ApiClientOptions(true, cacheLevel);
    if (sendSchoolId) {
      options.schoolIdHeader = schoolId;
    }
    options.httpContext = context;
    return this._apiClient.InvokeApi<SchoolMetaDataResponse>(getURL, HttpAction.Get, null, options);
  }

  GetCountryList(schoolId: string = null, sendSchoolId: boolean = false, cacheLevel = CachePriority.CacheFirst) {
    const getURL = `wpapi/Countries`;
    const options: ApiClientOptions = new ApiClientOptions(true, cacheLevel);
    if (sendSchoolId) {
      options.schoolIdHeader = schoolId;
    }
    return this._apiClient.InvokeApi<CountryResponse[]>(getURL, HttpAction.Get, null, options);
  }

  getCountryCode(schoolId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<SettingUpdateRequest> {
    const getUrl = `api/Schools/${schoolId}/CountryCode`;
    return this._apiClient.InvokeApi<SettingUpdateRequest>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
  }

  GetClassesInSchoolForAcademicYear(schoolId: string, academicYearId: string, useSchoolIdHeader: boolean = false): Observable<ClassResponse[]> {
    const getURL = `api/Schools/${schoolId}/AcademicYears/${academicYearId}/Classes`;
    const options: ApiClientOptions = new ApiClientOptions();
    if (useSchoolIdHeader) {
      options.schoolIdHeader = schoolId;
    }
    return this._apiClient.InvokeApi<ClassResponse[]>(getURL, HttpAction.Get, null, options);
  }

  GetClassesInSchool(schoolId: string, useSchoolIdHeader: boolean = false, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<ClassResponse[]> {
    const getURL = `api/Schools/${schoolId}/Classes`;
    const options: ApiClientOptions = new ApiClientOptions(true, cacheLevel);
    if (useSchoolIdHeader) {
      options.schoolIdHeader = schoolId;
    }
    return this._apiClient.InvokeApi<ClassResponse[]>(getURL, HttpAction.Get, null, options);
  }

  GetSchoolStudents(schoolId: string, academicYearId: string = null, cacheLevel: CachePriority = CachePriority.CacheFirst): Observable<StudentBasicInfo[]> {
    let getURL = `wpapi/Schools/${schoolId}/Students/BasicInfo`;
    if (!isNullOrUndefined(academicYearId)) {
      getURL += `?academicYearId=${academicYearId}`;
    }
    return this._apiClient.InvokeApi<StudentBasicInfo[]>(getURL, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
  }

  async saveUserGroup(name: string, userGroupTypeId: number, userGroupOrganizationId: string): Promise<string> {
    const postURL = `api/UserGroupOrganizations/${userGroupOrganizationId}/UserGroups`;
    const res = await this._apiClient.InvokeApi<any>(postURL, HttpAction.Post,
      {
        name: name.trim(),
        userGroupTypeId: userGroupTypeId,
        userGroupOrganizationId: userGroupOrganizationId,
      }).toPromise();

    return res.objectId;
  }

  async saveClass(schoolId: string, classRes: ClassCreateRequest | ClassResponse, enrollSelf: boolean = false, sendSchoolId: boolean = false): Promise<string> {
    classRes.name = classRes.name.trim();
    const postUrl = `api/Schools/${schoolId}/Classes?enrollSelf=${enrollSelf}`;
    const options: ApiClientOptions = new ApiClientOptions();
    if (sendSchoolId) {
      options.schoolIdHeader = schoolId;
    }
    return (await this._apiClient.InvokeApi<any>(postUrl, HttpAction.Post, classRes, options).toPromise()).objectId;
  }

  async isChild(): Promise<boolean> {
    const isStudent = +SessionStorageService.Get(GlobalConstants.UserTypeId) === UserType.Student;
    if (!isStudent) {
      return false;
    }

    const schoolId = SessionStorageService.Get(GlobalConstants.SchoolId);
    const schoolMetadata = await this.GetSchoolMetaData(schoolId).toPromise();

    return schoolMetadata.type === SchoolType.Family;
  }

  getSchoolsByDistrictId(
    {
      districtId,
      onlyLaunchedSchools,
      onlyActiveSchools,
      schoolType,
      schoolAccountStatus,
      withLiteracyBoxes,
      includeCurrentAcademicYear,
    }: RequiredBy<GetSchoolsByDistrictIdParams, 'districtId'>,
    cachePriority: CachePriority = CachePriority.NetworkFirst
  ) {
    const getURL = `api/districts/${districtId}/Schools`;
    const queryParams = this._apiClient.createURLSearchParams({
      onlyLaunchedSchools,
      onlyActiveSchools,
      schoolType,
      schoolAccountStatus,
      withLiteracyBoxes,
      includeCurrentAcademicYear,
    });
    const options: ApiClientOptions = new ApiClientOptions(true, cachePriority);

    return this._apiClient.InvokeApi<GetSchoolResponse[]>(getURL + queryParams, HttpAction.Get, null, options);
  }

  getSchoolsByUserId(userId: string, includeHasActiveClasses = false, cachePriority: CachePriority = CachePriority.NetworkFirst) {
    const getURL = `api/Users/${userId}/AdministeredSchools?includeHasActiveClasses=${includeHasActiveClasses}`;
    const options: ApiClientOptions = new ApiClientOptions(true, cachePriority);
    return this._apiClient.InvokeApi<SchoolResponse[]>(getURL, HttpAction.Get, null, options);
  }

  getClassesInSchoolLite(schoolId: string): Observable<ClassResponseLite[]> {
    const getURL = `api/Schools/${schoolId}/Classes/Lite`;
    const options: ApiClientOptions = new ApiClientOptions();
    return this._apiClient.InvokeApi<ClassResponseLite[]>(getURL, HttpAction.Get, null, options);
  }

  getAddress(schoolId: string) {
    const getURL = `api/Schools/${schoolId}/Address`;
    return this._apiClient.InvokeApi<PostalAddress>(getURL, HttpAction.Get, null, new ApiClientOptions(true));
  }

  updateAddress(schoolId: string, payload: Partial<PostalAddress>) {
    const putURL = `api/Schools/${schoolId}/Address`;
    return this._apiClient.InvokeApi<PostResponse>(putURL, HttpAction.Put, payload, new ApiClientOptions(true));
  }

  postSchoolPurchaseRequest(request: SchoolPurchaseRequest): Observable<PostResponse> {
    const url = `api/SchoolPurchaseRequests`;
    return this._apiClient.InvokeApi<PostResponse>(url, HttpAction.Post, request);
  }
}
