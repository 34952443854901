import {Injectable} from '@angular/core';
import {ApiClientService} from './api-client.service';
import {EndSession, StartSession} from '../models';
import {ApiClientOptions, PostResponse} from '../core/models';
import {HttpAction} from '../core/enums';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SessionsService {

    constructor(private _apiClient: ApiClientService) {
    }

    postStartSession(userId: string, startSession: StartSession, isOnline: boolean): Observable<PostResponse> {
        if (!userId || userId === 'null') {
            console.error('null userId');
            throw Error('null userId');
        }
        let postURL = "api/Users/" + userId + "/Sessions/Start";
        let options = new ApiClientOptions();
        options.waitForResponse = isOnline;
        return this._apiClient.InvokeApi<PostResponse>(postURL, HttpAction.Post, startSession, options);
    }

    postEndSession(userId: string, endSession: EndSession, isOnline: boolean): Observable<PostResponse> {
        let putURL = "api/Users/" + userId + "/Sessions/End";
        let options = new ApiClientOptions();
        options.userIdHeader = userId; // for when this is called when the next user logs in to a different shard
        options.waitForResponse = isOnline;
        return this._apiClient.InvokeApi<PostResponse>(putURL, HttpAction.Put, endSession, options);
    }
}
