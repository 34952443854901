import {BookFormatEnum} from '../../../../../../../src/app/enums';
import {EncyclopediaType} from '../encyclopedia-type.enum';

export enum MediaType {
    Book = 1,
    Video,
    ActionClip,
    Encyclopedia,
    AudioEpub = 6,
    Audio = 7,
    Interactive = 8,
    AudioEpub2 = 9,
    AugmentedReality = 10,
    Image = 11,
    Image360 = 12,
    LiveStream = 13,
    Table = 14,
    Map = 15,
    TedTalk = 16,
    HelpVideo = 17,
    Magazine = 18,
    Decodables,
    // Below fields only exists in FE and for mapping purpose only (there is no media type by these names)
    MagazineArticle,
    BackInTime,
    SpecialReport,
    StreetView,
    EarthView,
    MagazineSkillSheets
}

export namespace MediaType {
    export function getMediaTypeFromFormatType(formatType: BookFormatEnum, encyclopediaType: EncyclopediaType = null): MediaType {
        switch (formatType) {
            case BookFormatEnum.EPUB:
            case BookFormatEnum.PDF:
                return MediaType.Book;
            case BookFormatEnum.Video:
                return MediaType.Video;
            case BookFormatEnum.ActionClip:
                return MediaType.ActionClip;
            case BookFormatEnum.AudioEpub:
              return MediaType.AudioEpub;
            case BookFormatEnum.Audio:
              return MediaType.Audio;
            case BookFormatEnum.Interactive:
              return MediaType.Interactive;
            case BookFormatEnum.AudioEpub2:
              return MediaType.AudioEpub2;
            case BookFormatEnum.AugmentedReality:
              return MediaType.AugmentedReality;
            case BookFormatEnum.Image:
              return MediaType.Image;
            case BookFormatEnum.Image360:
              return MediaType.Image360;
            case BookFormatEnum.LiveStream:
              return MediaType.LiveStream;
          case BookFormatEnum.TedTalk:
            return MediaType.TedTalk;
          case BookFormatEnum.HelpVideo:
            return MediaType.HelpVideo;
          case BookFormatEnum.Encyclopedia:
            switch (encyclopediaType) {
              case EncyclopediaType.BackInTime:
                return MediaType.BackInTime;
              case EncyclopediaType.SpecialReport:
                return MediaType.SpecialReport;
              default:
                return MediaType.Encyclopedia;
            }
          case BookFormatEnum.Table:
            return MediaType.Table;
          case BookFormatEnum.Map:
            return MediaType.Map;
          case BookFormatEnum.Magazine:
            return MediaType.Magazine;
          case BookFormatEnum.Decodables:
              return MediaType.Decodables;
          default:
            return MediaType.Book;

        }
    }

    export function getFormatTypeFromMedia(mediaType: MediaType): BookFormatEnum {
        switch (mediaType) {
            case MediaType.Book:
                return BookFormatEnum.EPUB;
            case MediaType.Video:
                return BookFormatEnum.Video;
            case MediaType.ActionClip:
                return BookFormatEnum.ActionClip;
            case MediaType.AudioEpub:
              return BookFormatEnum.AudioEpub;
            case MediaType.Audio:
              return BookFormatEnum.Audio;
            case MediaType.Interactive:
              return BookFormatEnum.Interactive;
            case MediaType.AudioEpub2:
              return BookFormatEnum.AudioEpub2;
            case MediaType.AugmentedReality:
              return BookFormatEnum.AugmentedReality;
            case MediaType.Image:
              return BookFormatEnum.Image;
            case MediaType.Image360:
              return BookFormatEnum.Image360;
            case MediaType.LiveStream:
              return BookFormatEnum.LiveStream;
            case MediaType.TedTalk:
              return BookFormatEnum.TedTalk;
            case MediaType.HelpVideo:
              return BookFormatEnum.HelpVideo;
            case MediaType.Encyclopedia:
            case MediaType.BackInTime:
            case MediaType.SpecialReport:
              return BookFormatEnum.Encyclopedia;
            case MediaType.Table:
              return BookFormatEnum.Table;
            case MediaType.Map:
              return BookFormatEnum.Map;
            case MediaType.Magazine:
              return BookFormatEnum.Magazine;
            case MediaType.Decodables:
                return BookFormatEnum.Decodables;
        }
    }
}
