export enum EncyclopediaType {
    Articles = 1,
    BackInTime,
    Maps,
    HistoricalMaps,
    Images,
    Tables,
    Videos,
    Images360,
    SpecialReport,
    ResourceGuides,
    Dictionary
}
