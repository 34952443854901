import {Injectable} from '@angular/core';
import {StudentReadingProgress} from 'app/models/student-reading-progres';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RequiredBy} from '../../../projects/consumer/src/app/utils/utility-types';
import {isNullOrUndefined} from '../shared/utils/global.utils';
import {ApiClientService} from '../core/api-client.service';
import {CachePriority, HttpAction, STATUS} from '../core/enums';
import {ApiClientOptions, PostResponse} from '../core/models';
import {StudentUsernameIdsName} from '../core/models/student-username-ids-name';
import {BookFormatEnum, BookTagType, ClassificationType} from '../enums';
import {GlobalConstants} from '../globals';
import {FastSubShelf, GuidList, NumberPerDay, StudentLexileHistoryResponse, StudentResponse, UserUpdateRequest} from '../models';
import {BookTag} from '../models/book-tag';
import {NameRequest} from '../models/name-request';
import {SelfServiceStudentResponse} from '../models/self-service-student-response';
import {StudentEditRequest} from '../models/student/student-edit-request';
import {StudentRegisterRequest} from '../models/student/student-register-request';
import {CommunityPrivacy} from '../enums/community-privacy.enum';
import {StudentResponseWithClassIds} from '../../../projects/mindplay/src/app/core/models/student-response-with-classIds';
import {HttpContext} from '@angular/common/http';

export interface GetBookTagsForUserIdAndShelfIdParams {
  userId: string;
  userGroupId: string;
  bookTagType: BookTagType;
  userGroupOrganizationId: string;
  shelfId: string;
  grade: number;
  searchTerm: string;
  formatType: BookFormatEnum | string;
  searchOnShelfBookTagsOnly: boolean;
  onlyLightSailReady: boolean;
  excludeDrm: boolean;
  excludeNonDrm: boolean;
  take: number;
  religionTags: string;
  noReligion: boolean;
  privateLibrary: boolean;
  minAvailableCommunityContentPrivacy: CommunityPrivacy;
  maxAvailableCommunityContentPrivacy: CommunityPrivacy;
  availableCommunityContent: boolean;
  communityLibraries: boolean;
  bookTagId: string;
  decodableMapIds: string;
  decodableDistinctions: string;
  classification: ClassificationType
}

interface SearchStudentsInSchoolParams {
  schoolId: string;
  search: string;
  bySis: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StudentService {

    constructor(private apiClient: ApiClientService) {
    }

    //Get Section
    Get(studentId, cacheLevel: CachePriority = CachePriority.NetworkFirst, context: HttpContext = null): Observable<StudentResponse> {
        const getURL = 'api/Students/' + studentId;
        const options = new ApiClientOptions(true, cacheLevel);
        options.httpContext = context;
        return this.apiClient.InvokeApi(getURL, HttpAction.Get, null, options);
    }

    getStudentByUserId(userId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<StudentResponse> {
        return this.apiClient.InvokeApi(`api/Users/${userId}/Student`, HttpAction.Get,
            null, new ApiClientOptions(true, cacheLevel));
    }

    GetStudentsByClass(classId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<StudentResponse[]> {
        const getURL = 'api/Classes/' + classId + '/Students';
        return this.apiClient.InvokeApi(getURL, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    GetAllStudents(cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<StudentResponse[]> {
        const getURL = 'api/Students';
        return this.apiClient.InvokeApi(getURL, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    GetThoughtsPerDay(userId: string, fromDate: string, toDate: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<NumberPerDay[]> {
        const getURL = `api/Users/${userId}/ThoughtsPerDay?fromDate=${fromDate}&toDate=${toDate}`;
        return this.apiClient.InvokeApi<NumberPerDay[]>(getURL, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    GetPasswordChangeStatus(userId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<StudentResponse> {
        const getURL = 'api/Users/' + userId;
        return this.apiClient.InvokeApi(getURL, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    PutNewPassword(userId: string, newPassword: string, requiresChange: boolean): Promise<boolean> {
        const setURL = 'api/Users/' + userId;
        const content: UserUpdateRequest = {password: newPassword, passwordChangeRequired: requiresChange};

        return this.apiClient.InvokeApi(setURL, HttpAction.Put, content).toPromise().then((res) => {
            return res === STATUS.ACCEPTED;
        }).catch((err) => {
            return false;
        });
    }

    getLexileHistory(studentId: string, maxDate?: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Promise<StudentLexileHistoryResponse> {
        let getUrl = `api/Students/${studentId}/LastLexileHistory`;
        if (!isNullOrUndefined(maxDate)) {
            getUrl += `?maxDate=${maxDate}`;
        }
        return this.apiClient.InvokeApi<StudentLexileHistoryResponse>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel)).toPromise();
    }

    getStudentClassesByAcademicYearId(
        studentId: string,
        academicYearId: string,
        cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<any> {
        const getUrl = `api/Students/${studentId}/AcademicYears/${academicYearId}/Classes`;
        return this.apiClient.InvokeApi(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    getStudentReadingProgress(userId: string, classId: string, from: string, to: string, includeVideoStats = false, includeAudioStats = false, includeInteractiveBookStats = false, libraryCollectionId = '', minutesReadInThisClassOnly = false, cacheLevel: CachePriority = CachePriority.NetworkFirst): Promise<StudentReadingProgress> {
        const queryParams = this.apiClient.createURLSearchParams({
            to,
            from,
            includeVideoStats,
            includeAudioStats,
            includeInteractiveBookStats,
            libraryCollectionId,
            minutesReadInThisClassOnly
        });
        const getUrl = `api/Classes/${classId}/Users/${userId}/StudentReadingProgress${queryParams}`;
        return this.apiClient.InvokeApi<StudentReadingProgress>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel)).toPromise();
    }

    getStudentHWAssignment(studentId: string, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<any> {
        const getUrl = `api/Students/${studentId}/HomeworkPlans/Assignments`;
        return this.apiClient.InvokeApi<any>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    postHWContentsCompleted(studentId: string, homeworkAssignmentId: string, contentIds: string[]): Promise<PostResponse> {
        const postUrl = `api/Students/${studentId}/HomeworkAssignments/${homeworkAssignmentId}/ContentsCompleted`;
        return this.apiClient.InvokeApi<PostResponse>(postUrl, HttpAction.Post, contentIds).toPromise();
    }

    getBookTags(type: BookTagType, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<BookTag[]> {
        return this.apiClient.InvokeApi<BookTag[]>(`api/BookTags?bookTagType=${type}`, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    getBookTagsForUserIdAndShelfId(
      {
        userId,
        userGroupId,
        bookTagType,
        userGroupOrganizationId,
        shelfId,
        grade,
        searchTerm,
        formatType,
        searchOnShelfBookTagsOnly,
        onlyLightSailReady = true,
        excludeDrm = false,
        excludeNonDrm = false,
        take = 1000,
        religionTags,
        noReligion,
        privateLibrary = null,
        minAvailableCommunityContentPrivacy = null,
        maxAvailableCommunityContentPrivacy = null,
        availableCommunityContent = null,
        communityLibraries = null,
        bookTagId = null,
        decodableMapIds = null,
        decodableDistinctions = null,
        classification = null
      }: RequiredBy<GetBookTagsForUserIdAndShelfIdParams, 'userGroupOrganizationId' | 'userGroupId' | 'userId' | 'shelfId'>,
    ) {
      const queryParams = this.apiClient.createURLSearchParams({
        bookTagType,
        onlyLightSailReady,
        excludeDrm,
        excludeNonDrm,
        grade,
        searchTerm,
        formatType,
        searchOnShelfBookTagsOnly,
        take,
        religionTags,
        noReligion,
        privateLibrary,
        minAvailableCommunityContentPrivacy,
        maxAvailableCommunityContentPrivacy,
        availableCommunityContent,
        communityLibraries,
        bookTagId,
        decodableMapIds,
        decodableDistinctions,
        classification
      });
      const getURL = `api/UserGroupOrganizations/${ userGroupOrganizationId }/UserGroups/${ userGroupId }/Users/${ userId }/Shelves/${ shelfId }/FastSubShelves${ queryParams }`;
      return this.apiClient.InvokeApi<FastSubShelf[]>(getURL, HttpAction.Get);
    }

  getBookTagsForBookTagMatchOnEachTypeFilter(
    {
      userId,
      userGroupId,
      userGroupOrganizationId,
      publishedUserDrafts = null,
      availableCommunityContent = null,
      shelfId,
      bookTagType,
      bookTagMatchOnEachType,
      allBookTagIds,
      grade,
      formatType,
      classification,
      onlyLightSailReady = true,
      includeBookAppropriatesRatings = false,
      minLexile = null,
      maxLexile = null,
      userLexile = null,
      take = 5000
    }
  ) {
    const queryParams = this.apiClient.createURLSearchParams({
      bookTagType,
      bookTagMatchOnEachType,
      allBookTagIds,
      grade,
      formatType,
      classification,
      onlyLightSailReady,
      publishedUserDrafts,
      availableCommunityContent,
      take,
      includeBookAppropriatesRatings,
      minLexile,
      maxLexile,
      userLexile,
    });
    const getURL = `api/UserGroupOrganizations/${ userGroupOrganizationId }/UserGroups/${ userGroupId }/Users/${ userId }/Shelves/${ shelfId }/FastSubShelves${ queryParams }`;
    return this.apiClient.InvokeApi<FastSubShelf[]>(getURL, HttpAction.Get)
      .pipe(
        map((subShelves) =>{
          return subShelves.filter((shelf) => !shelf.name.match(GlobalConstants.GuidPatternRegExp));
        })
      );
  }

    updateStudent(student: StudentEditRequest, schoolId: string, classId: string,
        userId: string, studentId: string): Observable<PostResponse> {
        const url = `api/Schools/${schoolId}/Classes/${classId}/Users/${userId}/Students/${studentId}`;
        const options: ApiClientOptions = new ApiClientOptions();
        if (schoolId) {
          options.schoolIdHeader = schoolId;
        }
        return this.apiClient.InvokeApi<PostResponse>(url, HttpAction.Put, student, options);
    }

    resetStudentPasswords(schoolId: string, guidList: GuidList = null,
        password?: string, changeRequired?: boolean): Observable<PostResponse> {
        const urlParams = this.apiClient.createURLSearchParams({password, changeRequired});
        const postUrl = `api/Schools/${schoolId}/Students/ResetPasswords${urlParams}`;
        return this.apiClient.InvokeApi<PostResponse>(postUrl, HttpAction.Post, guidList);
    }

    getStudentNamesByUserIds(schoolId: string, userIds: string[]): Observable<{ firstName, lastName, userId }[]> {
        const getUrl = `api/Schools/${schoolId}/StudentNames?userIds=${userIds.join(',')}`;
        return this.apiClient.InvokeApi<{ firstName, lastName, userId }[]>(getUrl, HttpAction.Get, null, new ApiClientOptions(true, CachePriority.NetworkFirst));
    }

    createStudent(student: StudentRegisterRequest, schoolId: string): Observable<{ studentId: string }> {
        const url = `api/Schools/${schoolId}/SelfServiceStudent`;
        return this.apiClient.InvokeApi<SelfServiceStudentResponse>(url, HttpAction.PostWithBodyResponse, student, new ApiClientOptions(true));
    }

    updateStudentName(studentId: string, payload: NameRequest) {
      const url = `api/Students/${studentId}/Name`;

      return this.apiClient.InvokeApi(url, HttpAction.Put, payload);
    }

  deleteStudentsFromAllClasses(schoolId: string, guidList: GuidList = null) {
    const deleteUrl = `api/Schools/${schoolId}/Students/BulkDelete`;
    const options = new ApiClientOptions();

    if (schoolId) {
      options.schoolIdHeader = schoolId;
    }

    return this.apiClient.InvokeApi<PostResponse>(deleteUrl, HttpAction.Put, guidList, options);
  }

  getClassIdsByStudentId(studentId: string) {
    const url = `api/Students/${studentId}/ClassIds`;

    return this.apiClient.InvokeApi<string[]>(url, HttpAction.Get);
  }

  searchStudentsInSchool(
    {
      schoolId,
      search,
      bySis
    }: SearchStudentsInSchoolParams
  ) {
    const url = `api/Schools/${schoolId}/Students/Find`;
    const queryParams = this.apiClient.createURLSearchParams({
      search,
      bySis,
    });

    return this.apiClient.InvokeApi<StudentUsernameIdsName[]>(url + queryParams, HttpAction.Get);
  }

  getStudentsBySchoolAndClasses(schoolId: string, classIds: string[]): Observable<StudentResponseWithClassIds[]> {
    const url = `api/Schools/${schoolId}/ClassStudents`;
    const options: ApiClientOptions = new ApiClientOptions();
    options.schoolIdHeader = schoolId;
    return this.apiClient.InvokeApi<StudentResponseWithClassIds[]>(url, HttpAction.PostWithBodyResponse, classIds, options);
  }
}
