import {Injectable} from '@angular/core';
import {ApiClientService} from '../core/api-client.service';
import {Observable} from 'rxjs';
import {CachePriority, HttpAction} from '../core/enums';
import {TeacherResponse} from '../models';
import {ApiClientOptions, PostResponse} from '../core/models';
import {GlobalConstants, UserMetadataConstants} from '../globals';
import {SessionStorageService} from '../localstorage/session-storage.service';
import {LoggerService} from '../services/logger.service';
import {NameRequest} from '../models/name-request';
import {UserMetadataService} from '../core/user-metadata.service';
import {AdminType} from '../enums/admin-type.enum';
import {isNullOrUndefined} from '../../../src/app/shared/utils/global.utils';
import {TeacherModel} from '../models/teacher-model';
import {TeacherLiteResponse} from '../core/models/teacher-lite-response';
import {TeacherSearchResponse} from '../core/models/teacher-search-response';
import {TeacherListResponse} from '../models/teacher-list-response';
import {TeacherCreateRequest} from '../models/teacher-create.request';
import {UpdateTeacherFieldsRequest} from '../models/teacher-update.request';
import {UserPasswordUpdateRequest} from '../models/user-password-update-request';
import {TeacherResponseWithClassIds} from '../../../projects/mindplay/src/app/core/models/teacher-response-with-classIds';

@Injectable({providedIn: 'root'})
export class TeacherService {

    constructor(private apiClient: ApiClientService,
                private _userMeta: UserMetadataService) {

    }

    //Get Section
    Get(teacherId, schoolId: string = null, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<TeacherResponse> {
        const getURL = 'api/Teachers/' + teacherId;
        const options: ApiClientOptions = new ApiClientOptions(true, cacheLevel);
        if (schoolId) {
            options.schoolIdHeader = schoolId;
        }
        return this.apiClient.InvokeApi<TeacherResponse>(getURL, HttpAction.Get, null, options);
    }

    GetTeachersByClass(classId, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<TeacherResponse[]> {
        const getURL = 'api/Classes/' + classId + '/Teachers';
        return this.apiClient.InvokeApi<TeacherResponse[]>(getURL, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    getTeachersByClassLite(classId: string, cacheLevel: CachePriority = CachePriority.CacheFirst): Promise<TeacherLiteResponse[]> {
      const url = `api/Classes/${classId}/Teachers/Lite`;
      const apiClientOptions = new ApiClientOptions(true, cacheLevel);
      return this.apiClient.InvokeApi<TeacherLiteResponse[]>(url, HttpAction.Get, null, apiClientOptions).toPromise();
    }

    GetTeacherByUserId(userId, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<TeacherResponse> {
        const getURL = `api/Users/${userId}/Teacher`;
        return this.apiClient.InvokeApi<TeacherResponse>(getURL, HttpAction.Get, null, new ApiClientOptions(true, cacheLevel));
    }

    updateTeacherForTeacherId(teacherId: string, schoolId: string, teacherUpdateRequest: NameRequest, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<PostResponse> {
        const putURL = `api/Schools/${schoolId}/Teachers/${teacherId}/Name`;
        return this.apiClient.InvokeApi<PostResponse>(putURL, HttpAction.Put, teacherUpdateRequest, new ApiClientOptions(true, cacheLevel));
    }

    StartClassMonitoring(classId: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const teacherUserId: string = SessionStorageService.Get(GlobalConstants.UserId);
            LoggerService.Log(`StartClassMonitoring For ClassId: ${classId} TeacherUserId: ${teacherUserId}`);

            const url = `api/Users/${teacherUserId}/Classes/${classId}/Monitoring`;

            this.apiClient.InvokeApi<string>(url, HttpAction.Post).toPromise().then(() => {
                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    }

    StopClassMonitoring(classId: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const teacherUserId: string = SessionStorageService.Get(GlobalConstants.UserId);
            LoggerService.Log(`StopClassMonitoring For ClassId: ${classId} TeacherUserId: ${teacherUserId}`);

            const url = `api/Users/${teacherUserId}/ClassMonitoring${classId ? `?classId=${classId}` : ''}`;

            this.apiClient.InvokeApi<string>(url, HttpAction.Delete).toPromise().then(() => {
                resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    }

    getRelatedStudent(): Promise<any> {
        return new Promise((resolve, reject) => {
            Promise.all(
                [
                    this._userMeta.get(UserMetadataConstants.TeacherId),
                    this._userMeta.get(UserMetadataConstants.ClassId),
                    this._userMeta.get(UserMetadataConstants.CurrentGrade)
                ])
                .then(([teacherId, classId, gradeId]) => {
                    SessionStorageService.Save(GlobalConstants.DTClassId, classId);
                    const teacherUserId: string = SessionStorageService.Get(GlobalConstants.UserId);
                    const schoolId = SessionStorageService.Get(GlobalConstants.SchoolId);
                    const url = `api/Schools/${schoolId}/Classes/${classId}/TeacherUsers/${teacherUserId}/Teachers/${teacherId}/TestStudent?grade=${gradeId}`;
                    const options: ApiClientOptions = new ApiClientOptions();
                    // Add this header for district admin users.
                    const adminType = +SessionStorageService.Get(GlobalConstants.AdminType);
                    if (adminType === AdminType.DistrictAdmin) {
                        options.schoolIdHeader = schoolId;
                    }
                    this.apiClient.InvokeApi<any>(url, HttpAction.Post, null, options).toPromise().then((apiResult) => {
                        resolve(apiResult);
                    }).catch(error => {
                        reject(error);
                    });
                }).catch(error => {
                reject(error);
            });
        });
    }

    getTeachersBySchool(schoolId: string, academicYearId: string = null, sendSchoolIdHeader: boolean = true, cacheLevel: CachePriority = CachePriority.NetworkFirst): Observable<TeacherModel[]> {
        const url = `wpapi/Schools/${schoolId}/Teachers/GetTeachersBySchoolId?academicYearId=${isNullOrUndefined(academicYearId) ? '' : academicYearId}`;
        const apiClientOptions: ApiClientOptions = new ApiClientOptions(true, cacheLevel);
        if (sendSchoolIdHeader) {
            apiClientOptions.schoolIdHeader = schoolId;
        }
        return this.apiClient.InvokeApi<TeacherModel[]>(url, HttpAction.Get, null, apiClientOptions);
    }

    searchTeachersBySchool(schoolId: string, searchValue: string, cacheLevel: CachePriority = CachePriority.CacheFirst): Observable<TeacherSearchResponse[]> {
        const getUrl = `api/Schools/${schoolId}/Search/Teachers/${searchValue}`;
        const apiClientOptions: ApiClientOptions = new ApiClientOptions(true, cacheLevel);
        return this.apiClient.InvokeApi<TeacherSearchResponse[]>(getUrl, HttpAction.Get, null, apiClientOptions);
    }

    getTeachersByDistrictAndSchoolIds(districtId: string, schoolIds: string | string[], cacheLevel: CachePriority = CachePriority.NoCache) {
        const schoolIdsString = Array.isArray(schoolIds) ? schoolIds.join(',') : schoolIds;
        const getUrl = `/api/Districts/${districtId}/Teachers/List?schoolIds=${schoolIdsString}`;
        const apiClientOptions: ApiClientOptions = new ApiClientOptions(true, cacheLevel);

        return this.apiClient.InvokeApi<TeacherListResponse[]>(getUrl, HttpAction.Get, null, apiClientOptions);
    }

    createTeacher(userGroupOrganizationId: string, schoolId: string,
        teacherCreateRequest: TeacherCreateRequest): Observable<PostResponse> {
        const url = `api/UserGroupOrganizations/${userGroupOrganizationId}/Schools/${schoolId}/Teachers`;
        return this.apiClient.InvokeApi(url, HttpAction.Post, teacherCreateRequest);
    }

    updateTeacher(schoolId: string, teacherId: string, teacherRequest: UpdateTeacherFieldsRequest): Observable<PostResponse> {
        const url = `api/Schools/${schoolId}/Teachers/${teacherId}/FieldUpdate`;
        return this.apiClient.InvokeApi(url, HttpAction.Put, teacherRequest);
    }

    addEnrollments(schoolId: string, teacherId: string, classIds: string[]): Observable<PostResponse> {
        const request = classIds.map(classId => ({ value: classId }));
        const url = `api/Schools/${schoolId}/Teachers/${teacherId}/AddEnrollments`;
        return this.apiClient.InvokeApi(url, HttpAction.Put, request);
    }

    updateSchoolAdminRights(schoolId: string, userId: string, isSchoolAdmin: boolean): Observable<PostResponse> {
        const url = `api/Schools/${schoolId}/Users/${userId}/Teachers/SetAdminStatus`;
        return this.apiClient.InvokeApi(url, HttpAction.Put, { value: isSchoolAdmin });
    }

    resetTeacherPassword(schoolId: string, teacherId: string, updatePasswordRequest: UserPasswordUpdateRequest): Observable<PostResponse> {
        const url = `wpapi/Schools/${schoolId}/Teachers/${teacherId}/UpdatePassword`;
        return this.apiClient.InvokeApi(url, HttpAction.Put, updatePasswordRequest);
    }

    getTeachersBySchoolAndClasses(schoolId: string, classIds: string[]): Observable<TeacherResponseWithClassIds[]> {
      const url = `api/Schools/${schoolId}/ClassesTeachers`;
      const options: ApiClientOptions = new ApiClientOptions();
      options.schoolIdHeader = schoolId;
      return this.apiClient.InvokeApi<TeacherResponseWithClassIds[]>(url, HttpAction.PostWithBodyResponse, classIds, options);
    }
}
