//Inspired from ng-device-detector, tried importing as component but failing so extracted code which is needed
import {Injectable} from '@angular/core';
import {DeviceConstants} from '../globals';
import {UserOSType} from './enums';

@Injectable({providedIn: 'root'})
export class DeviceService {
    device: any;

    constructor() {
        this.fetchDeviceType();
    }

    public getDeviceType(): any {
        if (this.isMobile()) {
            return DeviceConstants.DEVICETYPE.PHONE;
        } else if (this.isTablet()) {
            return DeviceConstants.DEVICETYPE.TABLET;
        } else if (this.isDesktop()) {
            return DeviceConstants.DEVICETYPE.DESKTOP;
        } else {
            return DeviceConstants.DEVICETYPE.UNKNOWN;
        }
    }

    private fetchDeviceType() {
        let userAgent = window.navigator.userAgent;
        this.device = Object.keys(DeviceConstants.DEVICES).reduce((obj: any, item: any) => {
            obj[DeviceConstants.DEVICES[item]] = DeviceService.parseRegExp(userAgent, DeviceConstants.DEVICES_RE[item]);
            return obj;
        }, {});

        this.device = [
            DeviceConstants.DEVICES.ANDROID,
            DeviceConstants.DEVICES.ANDROID_TAB,
            DeviceConstants.DEVICES.I_PAD,
            DeviceConstants.DEVICES.IPHONE,
            DeviceConstants.DEVICES.I_POD,
            DeviceConstants.DEVICES.NEXUS_MOBILE,
            DeviceConstants.DEVICES.NEXUS_TABLET,
            DeviceConstants.DEVICES.BLACKBERRY,
            DeviceConstants.DEVICES.FIREFOX_OS,
            DeviceConstants.DEVICES.CHROME_BOOK,
            DeviceConstants.DEVICES.WINDOWS_PHONE,
            DeviceConstants.DEVICES.PS4,
            DeviceConstants.DEVICES.CHROMECAST,
            DeviceConstants.DEVICES.APPLE_TV,
            DeviceConstants.DEVICES.GOOGLE_TV,
            DeviceConstants.DEVICES.VITA,
            DeviceConstants.DEVICES.WINDOWS
        ].reduce((previousValue, currentValue) => {
            return (previousValue === DeviceConstants.DEVICES.UNKNOWN && this.device[currentValue]) ? currentValue : previousValue;
        }, DeviceConstants.DEVICES.UNKNOWN);
    }

    private isMobile() {
        return [
            DeviceConstants.DEVICES.ANDROID,
            DeviceConstants.DEVICES.IPHONE,
            DeviceConstants.DEVICES.I_POD,
            DeviceConstants.DEVICES.NEXUS_MOBILE,
            DeviceConstants.DEVICES.BLACKBERRY,
            DeviceConstants.DEVICES.FIREFOX_OS,
            DeviceConstants.DEVICES.WINDOWS_PHONE,
            DeviceConstants.DEVICES.VITA
        ].some((item) => {
            return this.device == item;
        });
    };

    private isTablet() {
        return [
            DeviceConstants.DEVICES.I_PAD,
            DeviceConstants.DEVICES.NEXUS_TABLET,
            DeviceConstants.DEVICES.ANDROID_TAB,
            DeviceConstants.DEVICES.FIREFOX_OS
        ].some((item) => {
            return this.device == item;
        });
    };

    private isDesktop() {
        return [
            DeviceConstants.DEVICES.PS4,
            DeviceConstants.DEVICES.CHROME_BOOK,
            DeviceConstants.DEVICES.UNKNOWN
        ].some((item) => {
            return this.device == item;
        });
    };

    static parseRegExp(string: string, regex: any): any {
        if (typeof regex === 'string') {
            regex = new RegExp(regex);
        }

        if (regex instanceof RegExp) {
            return regex.test(string);
        }
        else if (regex && Array.isArray(regex.and)) {
            return regex.and.every(function (item: any) {
                return DeviceService.parseRegExp(string, item);
            });
        }
        else if (regex && Array.isArray(regex.or)) {
            return regex.or.some(function (item: any) {
                return DeviceService.parseRegExp(string, item);
            });
        }
        else if (regex && regex.not) {
            return !DeviceService.parseRegExp(string, regex.not);
        }
        else {
            return false;
        }
    }

    public getOperatingSystemType() {
        //get the device type, and set the osType
        switch (this.device) {
            case DeviceConstants.DEVICES.ANDROID:
            case DeviceConstants.DEVICES.ANDROID_TAB:
            case DeviceConstants.DEVICES.NEXUS_MOBILE:
            case DeviceConstants.DEVICES.NEXUS_TABLET:
                return UserOSType.Android;
            case DeviceConstants.DEVICES.WINDOWS:
                return UserOSType.Windows;
            case DeviceConstants.DEVICES.CHROME_BOOK:
                return UserOSType.CrOS;
            case DeviceConstants.DEVICES.I_PAD:
            case DeviceConstants.DEVICES.IPHONE:
            case DeviceConstants.DEVICES.I_POD:
                return UserOSType.IOS;
            default:
                return UserOSType.Default;
        }
    }
}
